import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwri_rcitaffhypclf4hn6lty2vr6nq/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwri_rcitaffhypclf4hn6lty2vr6nq/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.11_next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwri_rcitaffhypclf4hn6lty2vr6nq/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18_dm5cwi5sepov27l6rdk7d6a3te/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18_dm5cwi5sepov27l6rdk7d6a3te/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/ui/dist/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18_dm5cwi5sepov27l6rdk7d6a3te/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-default\",\"src\":[{\"path\":\"./Graphik-Regular-Web.woff2\",\"weight\":\"400\"},{\"path\":\"./Graphik-Medium-Web.woff2\",\"weight\":\"500\"},{\"path\":\"./Graphik-Semibold-Web.woff2\",\"weight\":\"600\"},{\"path\":\"./Graphik-Bold-Web.woff2\",\"weight\":\"700\"}],\"display\":\"swap\"}],\"variableName\":\"GraphikRegular\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18_dm5cwi5sepov27l6rdk7d6a3te/node_modules/next/font/local/target.css?{\"path\":\"fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-headline\",\"src\":[{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"400\"},{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"500\"},{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"600\"},{\"path\":\"./GT-Super-Display-Bold.woff2\",\"weight\":\"700\"}],\"display\":\"swap\"}],\"variableName\":\"GtSuper\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/components/Image/Image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/ui/src/components/Logo/wander-logo-with-trademark.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/components/Popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/ui/src/providers/NotificationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wander-apps/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/app/WanderChat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/components/LazyRender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterSlotWrapper"] */ "/vercel/path0/wander-apps/web/features/footerSection/components/FooterSlot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/tracking/components/Tracking.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/user/components/User_AuthenticationDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wander-apps/web/features/userProfile/components/AskUserNameIfNeeded.tsx");
