'use client';

import React, { forwardRef, useState } from 'react';

function handleLoading(img: HTMLImageElement, setBlurComplete: (b: boolean) => void, placeholder?: 'blur') {
  const src = img?.src;
  if (!img || img.getAttribute('data-loaded-src') === src) {
    return;
  }
  img.setAttribute('data-loaded-src', src);
  const p = 'decode' in img ? img.decode() : Promise.resolve();
  p.catch(() => {}).then(() => {
    if (!img.parentElement || !img.isConnected) {
      // Exit early in case of race condition:
      // - onload() is called
      // - decode() is called but incomplete
      // - unmount is called
      // - decode() completes
      return;
    }
    if (placeholder === 'blur') {
      setBlurComplete(true);
    }
  });
}

export type StaticImage = {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
  blurWidth?: number;
  blurHeight?: number;
};

type AriaAttr = Omit<React.AriaAttributes, React.AriaRole>;

type Props = {
  src: string | StaticImage;
  alt: string;
  loading?: 'eager' | 'lazy';
  placeholder?: 'blur';
  blurDataURL?: string;
  style?: React.CSSProperties;
  className?: string;
  fetchPriority?: 'auto' | 'high' | 'low';
  innerRef?: React.Ref<HTMLImageElement>;
  draggable?: boolean;
  title?: string;
  onContextMenu?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  // for next/image compatibility
  quality?: number;
  srcset?: string;
  sizes?: string;
  priority?: boolean;
  unoptimized?: boolean;
} & (
  | {
      width: number;
      height: number;
    }
  | { fill: true }
) &
  AriaAttr;

const Image = forwardRef(
  (
    {
      src,
      style,
      placeholder,
      innerRef,
      blurDataURL,
      quality: _quality,
      srcset: _srcset,
      sizes: _sizes,
      priority: _priority,
      unoptimized: _unoptimized,
      ...props
    }: Props,
    ref: React.Ref<HTMLImageElement>
  ) => {
    // handle nextjs StaticImageData
    if (typeof src !== 'string') {
      blurDataURL = src?.blurDataURL;
      src = src?.src;
    }

    const [blurComplete, setBlurComplete] = useState(false);

    const backgroundImage = !blurComplete && placeholder === 'blur' ? `url("${blurDataURL}")` : null;

    const placeholderStyle = backgroundImage
      ? {
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundImage,
          ...style,
        }
      : {};

    const imgStyle = Object.assign(
      'fill' in props && props.fill
        ? {
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
          }
        : {},
      style || {},
      placeholderStyle,
      { color: 'transparent' }
    );

    return (
      <img
        src={src}
        {...props}
        decoding='async'
        ref={ref || innerRef}
        style={imgStyle}
        onLoad={(event) => {
          const img = event.currentTarget;
          handleLoading(img, setBlurComplete, placeholder);
        }}
        // @ts-ignore - fix if fill is in props
        // eslint-disable-next-line react/no-unknown-property
        fill={undefined}
      />
    );
  }
);

Image.displayName = 'Image';

export default Image;
