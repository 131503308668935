'use client';

import type { MeUser } from '@wandercom/users-defs';
import * as cookie from 'cookie';
import track from './track';

const trackSignInSignUp = async (user: MeUser) => {
  try {
    track({
      event: 'user_signed_in',
      data: {
        user_id: user.id,
        name: user.name,
        email: user.email,
        source: user.authProvider || cookie.parse(document.cookie).l_source || 'unknown',
      },
    });
  } catch (error) {
    console.error('Error tracking sign in or sign up', error);
  }
};

export default trackSignInSignUp;
